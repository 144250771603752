import React, {Component} from 'react';
import CookieConsent from "react-cookie-consent";
import {FormattedMessage, IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import ProtectImmunity from './components/sections/protect-immunity';
import VirusEstonia from './components/sections/virus-estonia';
import VirusSpread from './components/sections/virus-spread';
import VirusSpreadHow from './components/sections/virus-spread-how';
import VirusSymptoms from './components/sections/virus-symptoms';
import VirusEstoniaRe from './components/sections/virus-estonia-re';
import VirusDanger from './components/sections/virus-danger';
import MiddleBuyNow from './components/sections/middle-buy-now';
import StayHealthy from './components/sections/stay-healthy';
import Bioflavonoids from './components/sections/bioflavonoids';
import Miracle from './components/sections/miracle';
import WhyChoose from './components/sections/why-choose';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const { locale } = this.props;
        return (
            <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale} />
                    <TopBanner locale={locale} />
                    <ProtectImmunity locale={locale} />
                    <VirusEstonia/>
                    <VirusSpread/>
                    <VirusSpreadHow/>
                    <VirusSymptoms/>
                    <VirusEstoniaRe/>
                    <VirusDanger/>
                    <MiddleBuyNow/>
                    <StayHealthy/>
                    <Bioflavonoids/>
                    <Miracle/>
                    <WhyChoose locale={locale} />
                    {/* <Specialists/> */}
                    <Share/>
                    <Footer locale={locale} />
                    <CookieConsent contentClasses="cookie-content" buttonText = "Selge">
                        <FormattedMessage id="cookie" />
                    </CookieConsent>
                </div>
            </IntlProvider>
        );
    }
}
    

export default Page;
