import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({order, logo, text, large}) => {
    const classes = cx({
        'virus-danger-item': true,
        'virus-danger-item--large': large
    });
    return (
        <div className={[classes, order].join(" ")}>
            <img
                src={logo}
            />
            <div className="virus-danger-item__text">
                {text}
            </div>
        </div>
    );
}