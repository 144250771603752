import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="virus-spread">
        <div className="virus-spread__header">
            <FormattedMessage id="virus.spread.header" />
        </div>
        <div className="virus-spread__items">
            <Item
                large
                url="/static/images/virus-spread-cough.png"
            >
                <div>
                    <FormattedMessage id="virus.spread.left" />
                </div>
            </Item>
            <Item
                url="/static/images/virus-spread-eyes.png"
                >
                <div
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'virus.spread.center'})}}
                />
            </Item>
            <Item
                url="/static/images/virus-spread-surfaces.png"
            >
                <div>
                    <FormattedMessage id="virus.spread.right" />
                </div>
            </Item>
        </div>
    </div>
));
