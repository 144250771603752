import React from 'react';
import BuyNowButton from '../../common/buy-now-button'
import './index.css';

export default () => (
    <div className="middle-buy-now">
        <img
            className="middle-buy-now__bottle"
            src="/static/images/middle-buy-now-bottle.png"
        />
        <span className="middle-buy-now__button">
            <BuyNowButton />
        </span>
    </div>
);
