import React from 'react';
import './HeaderText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="virus-danger-header-text">
        <FormattedMessage id="virus.danger.header" />
    </div>
);

