import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => (
    <div className="protect-immunity">
        <HeaderText locale={locale} />
        <div className="protect-immunity-items">
            <Item
                logo="/static/svg/about-shield.svg"
                header={<FormattedMessage id="protect.immunity.left.header" />}
            >
                <div className="protect-immunity-item-left-text">                
                    <FormattedMessage id="protect.immunity.left" />
                </div>
            </Item>
            <Item
                logo="/static/svg/about-breath.svg"
                header={<FormattedMessage id="protect.immunity.center.header" />}
            >
                <div className="protect-immunity-item-center-text">
                    <FormattedMessage id="protect.immunity.center" />
                </div>
            </Item>
            <Item
                logo="/static/svg/about-gut.svg"
                header={<FormattedMessage id="protect.immunity.right.header" />}
            >
                <div 
                    className="protect-immunity-item-right-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'protect.immunity.right'})}}
                />
            </Item>
        </div>
    </div>
));