import React from 'react';
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="miracle">
        <div className="miracle-header__text">
            <FormattedMessage id="miracle.header" />
        </div>
        <img className="miracle-header__image" alt="" src="/static/images/miracle-banner.png" />
        <div className="miracle-content">
            <div className="miracle-content__item">
                <img
                    src="/static/svg/miracle-point.svg"
                />
                <div className="miracle-content__text">
                    <FormattedMessage id="miracle.first.point" />
                </div>
            </div>
            <div className="miracle-content__item">
                <img
                    src="/static/svg/miracle-point.svg"
                />
                <div className="miracle-content__text">
                    <FormattedMessage id="miracle.second.point" />
                </div>
            </div>
            <div className="miracle-content__item">
                <img
                    src="/static/svg/miracle-point.svg"
                />
                <div className="miracle-content__text">
                    <FormattedMessage id="miracle.third.point" />
                </div>
            </div>
        </div>
    </div>
);
