import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="virus-symptoms">
        <div className="virus-symptoms__header">
            <FormattedMessage id="virus.symptoms.header" />
        </div>
        <div className="virus-symptoms__items">
            <Item
                url="/static/svg/virus-symptoms-cold.svg"
                text={<FormattedMessage id="virus.symptoms.cold" />}
            />
            <Item
                large
                url="/static/svg/virus-symptoms-fever.svg"
                text={<FormattedMessage id="virus.symptoms.fever" />}
            />
            <Item
                url="/static/svg/virus-symptoms-cough.svg"
                text={<FormattedMessage id="virus.symptoms.cough" />}
            />
            <Item
                url="/static/svg/virus-symptoms-throat.svg"
                text={<FormattedMessage id="virus.symptoms.throat" />}
            />
        </div>
    </div>
);
