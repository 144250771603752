import React from 'react';
import './FooterText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="virus-estonia-re-footer">
        <div className="virus-estonia-re-footer__text">
            <FormattedMessage id="virus.estonia.re.footer" />
        </div>
    </div>
);

