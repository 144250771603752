import React from 'react';
import './HeaderText.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'protect-immunity-header-text': true,
        'protect-immunity-header-text--ru': locale === 'ru'
    });
    
    return (
        <div className={classes}>
            <FormattedMessage id="protect.immunity.header" />
        </div>
    );
}

