import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default () => (
    <div className="virus-danger">
        <HeaderText/>
        <div className="virus-danger-items">
            <Item
                large
                logo="/static/svg/virus-danger-ill.svg"
                text ={<FormattedMessage id="virus.danger.ill" />}
                order="virus-danger-item--order-first"
            >     
            </Item>
            <Item
                logo="/static/svg/virus-danger-smoker.svg"
                text={<FormattedMessage id="virus.danger.smoker" />}
                order="virus-danger-item--order-first"
            >     
            </Item>
            <Item
                logo="/static/svg/virus-danger-overworked.svg"
                text={<FormattedMessage id="virus.danger.overworked" />}
                order="virus-danger-item--order-last"
            >   
            </Item>
            <Item
                logo="/static/svg/virus-danger-pregnant.svg"
                text={<FormattedMessage id="virus.danger.pregnant" />}
                order="virus-danger-item--order-middle"
            >    
            </Item>
            <Item
                logo="/static/svg/virus-danger-newmum.svg"
                text={<FormattedMessage id="virus.danger.newmum" />}
                order="virus-danger-item--order-last"
            >    
            </Item>
            <Item
                logo="/static/svg/virus-danger-kids.svg"
                text={<FormattedMessage id="virus.danger.kids" />}
                order="virus-danger-item--order-last"
            >     
            </Item>
        </div>
    </div>
);