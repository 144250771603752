import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div id="bioflavonoids"  className="bioflavonoids">
        <div className="bioflavonoids-header__text">
            <FormattedMessage id="bioflavonoids.header" />
        </div>
        <img className="bioflavonoids-header__image" alt="" src="/static/images/bioflavonoids-banner.png" />
        <img className="bioflavonoids-header__image--small" alt="" src="/static/images/bioflavonoids-banner--small.png" />
        <div className="bioflavonoids-content">
            <div className="bioflavonoids-content__item">
                <img
                    src="/static/svg/bioflavonoids-point.svg"
                />
                <div 
                    className="bioflavonoids-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bioflavonoids.first.point'})}}
                />
            </div>
            <div className="bioflavonoids-content__item">
                <img
                    src="/static/svg/bioflavonoids-point.svg"
                />
                <div 
                    className="bioflavonoids-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bioflavonoids.second.point'})}}
                />
            </div>
            <div className="bioflavonoids-content__item">
                <img
                    src="/static/svg/bioflavonoids-point.svg"
                />
                <div className="bioflavonoids-content__text">
                    <FormattedMessage id="bioflavonoids.third.point" />
                </div>
            </div>
        </div>
    </div>
));
