import React from 'react';
import BuyNowButton from '../../common/buy-now-button';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes_tl = cx({
        'why-choose__text': true,
        'why-choose__text--ru': locale === 'ru',
        'why-choose__top-left': true,
        'why-choose__top-left--ru': locale === 'ru'
    });

    const classes_tr = cx({
        'why-choose__text': true,
        'why-choose__text--ru': locale === 'ru',
        'why-choose__top-right': true,
        'why-choose__top-right--ru': locale === 'ru'
    });

    const classes_bl = cx({
        'why-choose__text': true,
        'why-choose__text--ru': locale === 'ru',
        'why-choose__bottom-left': true,
        'why-choose__bottom-left--ru': locale === 'ru'
    });

    const classes_br = cx({
        'why-choose__text': true,
        'why-choose__text--ru': locale === 'ru',
        'why-choose__bottom-right': true,
        'why-choose__bottom-right--ru': locale === 'ru'
    });

    const classes_btn = cx({
        'why-choose__button': true,
        'why-choose__button--ru': locale === 'ru'
    });
    
    return (
        <div id="why-choose"  className="why-choose">
            <div className="why-choose__header">
                <FormattedMessage id="why.choose.header" />
            </div>
            <img className="why-choose__top-left__img" src="/static/svg/why-choose-top-left.svg" alt="" />
            <div 
                className={classes_tl}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.choose.item.1'})}}
            />
            <img className="why-choose__top-right__img" src="/static/svg/why-choose-top-right.svg" alt="" />
            <div 
                className={classes_tr}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.choose.item.2'})}}
            />
            <img className="why-choose__bottom-left__img" src="/static/svg/why-choose-bottom-left.svg" alt="" />
            <div 
                className={classes_bl}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.choose.item.3'})}}
            />
            <img className="why-choose__bottom-right__img" src="/static/svg/why-choose-bottom-right.svg" alt="" />
            <div 
                className={classes_br}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.choose.item.4'})}}
            />
            <div className="why-choose__footer">
                <div className="why-choose__footer__text">
                    <FormattedMessage id="why.choose.footer" />
                </div>
            </div>
            <div className="why-choose__bottle-div">
                <img
                    className="why-choose__bottle"
                    src="/static/images/why-choose-bottle.png"
                />
            </div>
            <img
                className="why-choose__mobile"
                src="/static/images/why-choose--mobile.png"
                />
            <span className={classes_btn}>
                <BuyNowButton large />
            </span>
        </div>
    );
})
