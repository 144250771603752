import React from 'react';
import './index.css';
import FooterText from './FooterText';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="virus-estonia-re">
        <div className="virus-estonia-re-header__text">
            <FormattedMessage id="virus.estonia.re.header" />
        </div>
        <div className="virus-estonia-re-header">
            <img className="virus-estonia-re-header__image" alt="" src="/static/images/virus-estonia-re-banner.png" />
        </div>
        <div className="virus-estonia-re-content">
            <div className="virus-estonia-re-content__item">
                <img
                    src="/static/svg/virus-estonia-re-point.svg"
                />
                <div className="virus-estonia-re-content__text">
                    <FormattedMessage id="virus.estonia.re.first.point" />
                </div>
            </div>
            <div className="virus-estonia-re-content__item">
                <img
                    src="/static/svg/virus-estonia-re-point.svg"
                />
                <div className="virus-estonia-re-content__text">
                    <FormattedMessage id="virus.estonia.re.second.point" />
                </div>
            </div>
            <div className="virus-estonia-re-content__item">
                <img
                    src="/static/svg/virus-estonia-re-point.svg"
                />
                <div className="virus-estonia-re-content__text">
                    <FormattedMessage id="virus.estonia.re.third.point" />
                </div>
            </div>
        </div>
        <FooterText />
    </div>
);
