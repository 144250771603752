import React, {Component} from 'react';
import HeaderTop from "./HeaderTop";
import './index.css';

export default class Header extends Component {
    state = {
        menuOpen: false,
    };

    toggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    };

    render() {
        const {menuOpen} = this.state;
        const {locale} = this.props;
        return (
            <div className="header">
                <HeaderTop toggle={this.toggle} locale={locale} isOpen={menuOpen}/>
                {
                    !menuOpen
                }
            </div>
        );
    }
}
