import React from 'react';
import './FooterText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="stay-healthy-footer">
        <div className="stay-healthy-footer__text">
            <FormattedMessage id="stay.healthy.footer" />
        </div>
    </div>
);

